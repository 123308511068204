<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

const galleryOptions: SwiperOptions = {
  freeMode: true,
  lazy: true,
  observer: true,
  slidesPerView: 'auto',
  preventClicksPropagation: true,
  preventInteractionOnTransition: true
}
</script>

<template>
  <div class="relative max-w-full overflow-x-clip overflow-y-visible [&_*]:!cursor-grab [&_*]:select-none">
    <Gallery class="w-full whitespace-nowrap !p-0" :options="galleryOptions">
      <slot />
    </Gallery>
  </div>
</template>

<style lang="scss" scoped>
.gallery {
  padding: 0;
}

:deep(.swiper-button-prev), :deep(.swiper-button-next) {
  display: none !important;
}

@media screen and (max-width: 767px) {
  :deep(.swiper-container) {
    padding: 0 !important;
  }
}
</style>
